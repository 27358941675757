import { PageProps } from "gatsby";
import React from "react";

import { Software } from "../../components/pages/Software";

import { dnProps } from "../../js/utils";

const SoftwarePage = (props: PageProps) => (
  <Software {...{ ...dnProps, ...props }} />
);

export default SoftwarePage;
